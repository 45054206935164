const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/im;
const URL_PATTERN = /^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/im;

export default {
  loginValidationLayout: {
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    password: {
      type: 'string',
      label: 'Password',
    },
  },
  signupValidationLayout: {
    firstName: {
      type: 'string',
      label: 'First name',
    },
    lastName: {
      type: 'string',
      label: 'Last name',
    },
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    password: {
      type: 'string',
      label: 'Password',
      minlength: 8,
      maxlength: 20,
      number: true,
      uppercase: true,
    },
  },
  resetPasswordValidationLayout: {
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
  },
  resetPasswordTokenValidationLayout: {
    password: {
      type: 'string',
      label: 'Password',
      minlength: 8,
      maxlength: 20,
      number: true,
      uppercase: true,
    },
    confirmPassword: {
      type: 'string',
      label: 'Password',
      minlength: 8,
      maxlength: 20,
      number: true,
      uppercase: true,
    },
  },
  changePasswordValidationLayout: {
    password: {
      type: 'string',
      label: 'Password',
      minlength: 6,
    },
    newPassword: {
      type: 'string',
      label: 'Password',
      minlength: 8,
      maxlength: 20,
      number: true,
      uppercase: true,
    },
    confirmPassword: {
      type: 'string',
      label: 'Password',
      minlength: 8,
      maxlength: 20,
      number: true,
      uppercase: true,
    },
  },
  inviteValidationLayout: {
    firstName: {
      type: 'string',
      label: 'First name',
    },
    lastName: {
      type: 'string',
      label: 'Last name',
    },
    password: {
      type: 'string',
      label: 'Password',
      minlength: 8,
      maxlength: 20,
      number: true,
      uppercase: true,
    },
  },
  companyMemberInvitesValidationLayout: {
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    role: {
      type: 'string',
      label: 'Role',
    },
  },
  subscribeValidationLayout: {
    email: {
      type: 'string',
      label: 'Email',
      pattern: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/im,
    },
  },
  emailReferralValidationLayout: {
    firstName: {
      type: 'string',
      label: 'First name',
    },
    fromEmail: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    toEmail: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
  },
  socialReferralValidationLayout: {
    firstName: {
      type: 'string',
      label: 'First name',
    },
    fromEmail: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
  },
  notifyDialogValidationLayout: {
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
  },
  declineDialogValidationLayout: {
    reason: {
      type: 'string',
      label: 'Reason',
    },
    message: {
      type: 'string',
      label: 'Message',
      minlength: 20,
    },
  },
  declineDialogDueToAvailabilityValidationLayout: {
    reason: {
      type: 'string',
      label: 'Reason',
    },
    nextAvailableDate: {
      type: 'string',
      label: 'Next available date',
    },
    message: {
      type: 'string',
      label: 'Message',
      minlength: 20,
    },
  },
  destinationDialogValidationLayout: {
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    address: {
      type: 'object',
      label: 'Address',
    },
    travelMode :{
      type: 'string',
      label: 'Commute type',
    },
  },
  partnershipsLandingPageValidationLayout: {
    name: {
      type: 'string',
      label: 'First name',
      minlength: 2,
    },
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    company_name: {
      type: 'string',
      label: 'Company name',
      minlength: 2,
    },
    company_url: {
      type: 'string',
      label: 'Company Url',
      pattern: URL_PATTERN,
    },
  },
  businessLandingPageValidationLayout: {
    name: {
      type: 'string',
      label: 'First name',
      minlength: 2,
    },
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    company_name: {
      type: 'string',
      label: 'Business name',
      minlength: 2,
    },
    company_url :{
      type: 'string',
      label: 'Business Url',
      pattern: URL_PATTERN,
    },
  },
  universitiesLandingPageValidationLayout: {
    name: {
      type: 'string',
      label: 'First name',
      minlength: 2,
    },
    email: {
      type: 'string',
      label: 'Email',
      pattern: EMAIL_PATTERN,
    },
    university: {
      type: 'string',
      label: 'University name',
      minlength: 2,
    },
    phone_number: {
      type: 'string',
      label: 'Phone number',
      custom: 'phone_number',
    },
  },
  availabilityEventValidationLayout: {
    title: {
      type: 'string',
      label: 'Title',
      maxlength: 40,
    },
    start: {
      label: 'Start',
      type: 'string',
      pattern: /[0-9][0-9]\s\/\s[0-9][0-9]\s\/\s[0-9][0-9][0-9][0-9]/,
    },
    end: {
      label: 'End',
      type: 'string',
      pattern: /[0-9][0-9]\s\/\s[0-9][0-9]\s\/\s[0-9][0-9][0-9][0-9]/,
    },
    unitId: {
      type: 'string',
      label: 'Bed',
    },
  },
  importAirbnbDialogValidationLayout: {
    profileLink: {
      label: 'Profile link',
      type: 'string',
      patterns: [
        /^(https?:\/\/)?(www\.)?airbnb.[a-z.]{2,5}\/users\/show\/([0-9]{4,11})+$/gim, // e.g. https://www.airbnbn.com/users/show/1234567
        /^(https?:\/\/)?(www\.)?airbnb.[a-z.]{2,5}\/users\/([0-9]{4,11})+\/listings$/gim, // e.g. https://www.airbnbn.com/users/1234567/listings
        /^\/?users\/show\/([0-9]{4,11})+$/gim, // e.g. /users/show/1234567
        /^\/?users\/([0-9]{4,11})+\/listings$/gim, // e.g. /users/1234567/listings
      ],
    },
  },
  listingValidationLayout: {
    // Listing details
    1: {
      address: {
        type: 'custom',
      },
      coordinates: {
        type: 'custom',
      },
      furnishings: {
        type: 'string',
        label: 'Furnishings',
      },
      property_type: {
        type: 'string',
        label: 'Property type',
      },
      ownership: {
        type: 'string',
        label: 'Ownership type',
      },
      is_price_per_tenant: {
        type: 'boolean',
        label: 'Rental Type',
      },
      bedrooms_total: {
        type: 'number',
        label: 'Total bedrooms',
      },
      bedrooms_for_rent: {
        type: 'number',
        label: 'Bedrooms for rent',
      },
      unit_bed_types: {
        type: 'custom',
      },
      sleeping_area_types: {
        type: 'custom',
      },
      sleeping_area_bed_types: {
        type: 'custom',
      },
      bathrooms: {
        type: 'number',
        label: 'Bathrooms',
      },
    },
    // Availability
    2: {},
    // Price
    3: {
      currency: {
        type: 'string',
        label: 'Currency',
      },
      nightly_price: {
        type: 'custom',
      },
      seasonal_pricing: {
        type: 'custom',
      },
      bed_prices: {
        type: 'custom',
      },
      left_to_assign: {
        type: 'custom',
      },
      security_deposit: {
        type: 'custom',
      },
      cleaning_fee: {
        type: 'custom',
      },
    },
    // Amenities and Guidelines
    4: {
      laundry: {
        type: 'string',
        label: 'Laundry',
      },
      pets: {
        type: 'string',
        label: 'Pets',
      },
      parking: {
        type: 'string',
        label: 'Parking',
      },
      wifi_speed: {
        type: 'custom',
      },
      house_rules: {
        type: 'custom',
      },
    },
    // Photos
    5: {
      photos: {
        type: 'custom',
      },
      photo: {
        type: 'custom',
      },
      video: {
        type: 'custom',
        regex: /^(https?:\/\/)?(www\.youtube.com|youtu.?be)\/.+$/,
        error: 'Please add a valid YouTube link',
      },
      matterport_link: {
        type: 'custom',
        regex: /^https?:\/\/.+$/,
        error: 'Please add a valid 3D tour link',
      },
    },
    // Title and Description
    6: {
      title: {
        type: 'custom',
      },
      description: {
        type: 'string',
        label: 'Description',
        minlength: 100,
        maxlength: 5000,
        block_contact_info: true,
      },
    },
    // Management
    7: {
      manager_payout_method_id: {
        type: 'custom',
      },
      manager_fee: {
        type: 'custom',
      },
    },
    // Verification
    8: {},
  },
  housematePostValidationLayout: {
    birthday: {
      label: 'Birthday',
      type: 'custom',
      pattern: /[0-9][0-9]\s\/\s[0-9][0-9]\s\/\s[0-9][0-9][0-9][0-9]/,
      error: 'Birthday is required.',
    },
    profilePhoto: {
      label: 'Profile photo',
      type: 'custom',
      error: 'Please add a profile photo.',
    },
    cities: {
      label: 'City',
      type: 'custom',
      error: 'City is required.',
    },
    gender: {
      type: 'string',
      label: 'Gender',
      error: 'Select an option.',
    },
    personalPronoun: {
      type: 'string',
      label: 'Personal pronoun',
      error: 'Select an option.',
    },
    languages: {
      type: 'list',
      label: 'Languages spoken',
      error: 'Enter a language(s).',
    },
    about: {
      type: 'string',
      label: 'About me',
      minlength: 250,
      maxlength: 1000,
    },
    facebook: {
      type: 'string',
      label: 'Facebook',
      pattern: URL_PATTERN,
      optional: true,
    },
    instagram: {
      type: 'string',
      label: 'Instagram',
      pattern: URL_PATTERN,
      optional: true,
    },
    linkedin: {
      type: 'string',
      label: 'LinkedIn',
      pattern: URL_PATTERN,
      optional: true,
    },
    twitter: {
      type: 'string',
      label: 'Twitter',
      pattern: URL_PATTERN,
      optional: true,
    },
    budget: {
      type: 'number',
      label: 'Budget',
      min: 100,
      max: 20000,
    },
    roomType: {
      type: 'list',
      label: 'Room type',
    },
    cigarettes: {
      type: 'string',
      label: 'Cigarettes',
      category: 'lifestyle',
    },
    alcohol: {
      type: 'string',
      label: 'Alcohol',
      category: 'lifestyle',
    },
    marijuana: {
      type: 'string',
      label: 'Marijuana',
      category: 'lifestyle',
    },
    cleanliness: {
      type: 'string',
      label: 'Cleanliness',
      category: 'lifestyle',
    },
    lookingFor: {
      type: 'string',
      label: 'Looking for',
      minlength: 100,
      maxlength: 500,
    },
  },
  educationValidationLayout: {
    school_name: {
      type: 'string',
      label: 'School name',
      minlength: 5,
    },
    program: {
      type: 'string',
      label: 'Program',
      minlength: 5,
    },
    level: {
      type: 'string',
      label: 'Level',
    },
    graduation_year: {
      type: 'number',
      label: 'Graduation year',
      max: 3000,
      min: 1900,
    },
  },
  workExperienceValidationLayout: {
    employer_name: {
      type: 'string',
      label: 'Employer name',
      minlength: 1,
    },
    position: {
      type: 'string',
      label: 'Position',
      minlength: 1,
    },
    start: {
      label: 'Start date',
      type: 'string',
      pattern: /[0-9][0-9]\s\/\s[0-9][0-9][0-9][0-9]/,
    },
    end: {
      label: 'End date',
      type: 'string',
      pattern: /[0-9][0-9]\s\/\s[0-9][0-9][0-9][0-9]/,
      optional: true,
    },
  },
  rentalApplicationAboutValidationLayout: {
    about: {
      type: 'string',
      label: 'About',
      minlength: 250,
      maxlength: 2000,
    },
  },
  inquiryMessageValidationLayout: {
    message: {
      type: 'string',
      label: 'Message',
      minlength: 20,
      maxlength: 1000,
    },
  },
  acceptRequestValidationLayout: {
    message: {
      type: 'string',
      label: 'Message',
      maxlength: 2000,
      optional: true,
    },
    cancellation_policy_id: {
      type: 'string',
      error: 'Add a cancellation policy.',
    },
    company_payout_method_id: {
      type: 'string',
      error: 'Please connect a bank account.',
    },
    manager_payout_method_id: {
      type: 'string',
      error: 'Please connect a secondary bank account or turn off split payouts.',
      optional: true,
    },
  },
  payRequestValidationLayout: {
    payment_method_id: {
      type: 'string',
      error: 'Please add a payment method.',
    },
  },
  returnSecurityDepositValidationLayout: {
    company_payout_method_id: {
      type: 'string',
      error: 'Please add a payment method.',
    },
    totalReturnAmount: {
      type: 'number',
      label: 'You can only return a positive amount.',
      max: 20000,
      min: 1,
    },
  },
  submitUserReviewValidationLayout: {
    details: {
      maxlength: 1000,
      minlength: 20,
      type: 'string',
    },
    overallRating: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
  },
  submitListingReviewValidationLayout: {
    details: {
      maxlength: 1000,
      minlength: 20,
      type: 'string',
    },
    overall: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
    accuracy: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
    cleanliness: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
    location: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
    value: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
    support: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
    quality: {
      error: 'Please add a rating of at least 1 star.',
      max: 5,
      min: 0,
      type: 'number',
    },
  },
};
